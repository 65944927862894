import { getGoe4dTranslation, updateProposal } from "api";
// import { updateErrorModalState } from "auth/globalSlice";
import { useAppDispatch, useAppSelector, useSelectedProposal } from "hooks";
import { useCallback } from "react";
import { set_goe4d_data, set_selected_proposal, set_view_loading } from "redux/actions";
import { Contact } from "redux/models";
import {
    calculateCurrentAge,
    calculateCurrentDate,
    calculateEndDate,
    calculateSumOfAllOtherWealthValues,
    calculateSumValuesWithInflation,
    calculateSumValuesWithoutInflation,
    calculateTotalExpenditureValue,
    toGoe4DDate,
} from "utils/helper";

export const useGoe4d = () => {
    const dispatch = useAppDispatch();
    const orgInfo = useAppSelector((state) => state.global.orgInfo);
    const selectedContact = useAppSelector((state) => state.selectedContact);
    const { healthStatus } = useAppSelector((state) => state?.selectedContact);
    const selectedProposal = useAppSelector((state) => state.selectedProposal);
    const spireIndicativeQuote = useAppSelector((state) => state.spireIndicativeQuote);
    const { annualDisplayedProposal, displayedProposal } = useSelectedProposal();
    const {
        wealthItems,
        feeRate,
        definedBenefitPensions,
        otherIncomeItems,
        expenditures,
        includeAnnuities,
        statePensionIncome,
        riskScore = "",
    } = annualDisplayedProposal?.customData || selectedProposal?.customData;
    const cashReservesRate = useAppSelector((state) => state?.global?.orgInfo?.settings?.cashReservesRate);
    const defaultAnnuityRate = useAppSelector((state) => state?.global?.orgInfo?.settings?.defaultAnnuityRate);
    const fixedExpenseFactor = useAppSelector((state) => state?.global?.orgInfo?.settings?.fixedExpenseFactor);
    const totalWealth = Number(calculateSumOfAllOtherWealthValues(wealthItems));
    const cashReserves = totalWealth * Number(feeRate);
    const totalCashReserves = Number(cashReservesRate * totalWealth) + Number(cashReserves);
    const { healthPersonaPercentage } = useAppSelector((state) => state?.global.orgInfo?.settings);
    const { goodHealth, nonSmoker, smoker } = healthPersonaPercentage;

    const getAnnuityRatePercent = () => {
        if (healthStatus === "AboveAverage") {
            return goodHealth;
        } else if (healthStatus === "Average") {
            return nonSmoker;
        } else if (healthStatus === "BelowAverage") {
            return smoker;
        }
    };
    const annuityRate = getAnnuityRatePercent() || defaultAnnuityRate;

    const calculateAnnuityQuoteAmount = (additionalPayoutAnnuity: number) => {
        if (annualDisplayedProposal?.customData?.hasAnnuityManuallyChanged || additionalPayoutAnnuity === 0)
            return annualDisplayedProposal?.customData?.annuityQuoteAmount;
        return additionalPayoutAnnuity;
    };
    const calculateAllocationAnnuity = (allocationAnnuity: number) => {
        if (annualDisplayedProposal?.customData?.hasAnnuityManuallyChanged || allocationAnnuity === 0)
            return (
                annualDisplayedProposal?.customData?.annuityQuoteAmount *
                (getAnnuityRatePercent() || defaultAnnuityRate) *
                100 // AEX-1192
            );
        return allocationAnnuity;
    };
    const prepareData = useCallback(
        (selectedContact: Contact, orgInfo: any, futureAnnuityProj: boolean) => {
            const riskProfile = riskScore;
            const fixedExpenseFact = fixedExpenseFactor;
            const cashReserves = ~~Number(totalCashReserves) || 0;
            const inflationRate = orgInfo?.settings?.inflationRate;
            const lifeSpan = orgInfo?.settings?.lifeSpan || null;
            const retirementAge = orgInfo?.settings?.retirementAge || null;
            const dateOfBirth = toGoe4DDate(selectedContact?.birthdate);
            const endDate = calculateEndDate(dateOfBirth, lifeSpan);
            const currentDate = calculateCurrentDate();
            const currentAge = calculateCurrentAge(selectedContact?.birthdate);
            const statePension = statePensionIncome?.value || 0;
            const currentWealth =
                calculateSumOfAllOtherWealthValues(
                    annualDisplayedProposal?.customData?.wealthItems ||
                        displayedProposal?.customData?.wealthItems ||
                        selectedProposal?.customData?.wealthItems
                ) || 0;
            const defBenfIncome =
                calculateSumValuesWithoutInflation(annualDisplayedProposal?.customData?.definedBenefitPensions) || 0;
            const defBenfIncomeInflation = calculateSumValuesWithInflation(definedBenefitPensions) || 0;
            const guaranteedOtherIncome = calculateSumValuesWithoutInflation(otherIncomeItems) || 0;
            const guaranteedOtherIncomeInflation = calculateSumValuesWithInflation(otherIncomeItems) || 0;
            const expendituresValue = calculateTotalExpenditureValue(expenditures);
            const existingAnnuitiesIncome = ~~spireIndicativeQuote?.illustrationDetails?.incomePaymentAmount || 0; // incomeValue ??

            return {
                cashReserves: cashReserves,
                inflationRate: inflationRate,
                currentAge: currentAge,
                retirementAge: retirementAge,
                dateOfBirth: dateOfBirth,
                startDate: currentDate,
                endDate: endDate,
                currDate: currentDate,
                guaranteedOtherIncome: Number(guaranteedOtherIncome),
                guaranteedOtherIncomeInflation: Number(guaranteedOtherIncomeInflation),
                statePension: Number(statePension),
                defBenfIncome: defBenfIncome,
                defBenfIncomeInflation: defBenfIncomeInflation,
                stateIncome: Number(statePension),
                targetExpenditures: ~~Number(expendituresValue),
                targetConsumptionAmount: ~~Number(expendituresValue),
                reallocationFreq: "yearly",
                infusionType: "yearly",
                currentWealth:
                    Number(currentWealth) > Number(cashReserves)
                        ? Number(currentWealth) - Number(cashReserves)
                        : Number(currentWealth),
                existingAnnuitiesIncome: existingAnnuitiesIncome,
                includeAnnuities: includeAnnuities || false,
                futureAnnuityProj: futureAnnuityProj,
                lossThreshold: null,
                lifeSpan: lifeSpan,
                annuityRate: Number(Number(annuityRate).toFixed(4)),
                cashflowDate: currentDate,
                riskProfile: riskProfile,
                fixedExpenseFactor: fixedExpenseFact,
            };
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedProposal?.customData?.quotations, annualDisplayedProposal, spireIndicativeQuote]
    );
    const goe4dRequestData = (futureAnnuityProj: boolean) => prepareData(selectedContact, orgInfo, futureAnnuityProj);
    const fetchGoe4d = useCallback(
        async (futureAnnuityProj: boolean) => {
            dispatch(set_view_loading(true));
            try {
                dispatch(set_goe4d_data({}));
                const res = await getGoe4dTranslation(
                    goe4dRequestData(futureAnnuityProj),
                    orgInfo?.config?.goeConfigEmail
                );

                if (res?.status === 200) {
                    if (res?.data?.statusCode === 200) {
                        // console.log("response", res.data.body);
                        dispatch(set_goe4d_data(res?.data || {}));
                        // update proposals array
                        const goePersistedProposal = {
                            ...annualDisplayedProposal,
                            customData: {
                                ...annualDisplayedProposal?.customData,
                                wealthItems: [...displayedProposal?.customData?.wealthItems],
                                goalProbability: res?.data?.body?.goalProbability,
                                recommendedPortfolioId: res?.data?.body?.recommendedPortfolioId,
                                allocationAnnuity: calculateAllocationAnnuity(res?.data?.body?.allocationAnnuity),
                                additionalPayoutAnnuity: res?.data?.body?.additionalPayoutAnnuity,
                                annuityQuoteAmount: calculateAnnuityQuoteAmount(
                                    res?.data?.body?.additionalPayoutAnnuity
                                ),
                                recommendedConsumption: res?.data?.body?.recommendedConsumption,
                                proposedIncreaseInAnnuity: res?.data?.body?.allocationAnnuity || 0,
                                annuityRate: Number(Number(annuityRate).toFixed(4)),
                            },
                        };
                        // updateproposal
                        try {
                            const res = await updateProposal(
                                goePersistedProposal,
                                selectedContact.contactId || selectedContact._id
                            );
                            if (res?.success) {
                                // set proposal to store
                                dispatch(set_selected_proposal(res?.proposal));
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }
                }
            } catch (err: any) {
                console.error(err);
            }
            dispatch(set_view_loading(false));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, orgInfo, displayedProposal, set_goe4d_data]
    );
    return {
        fetchGoe4d,
        totalCashReserves,
    };
};
